<template>
  <div>
    <div class="form-row w-1/2">
      <SelectInput
        ref="bundle"
        name="bundleId"
        label="bundle"
        :options="bundleOptions"
        :editMode="!noAppRoles && !readonly"
        class="bundle-name"
        noClear
      />
    </div>
    <InfoText v-if="noAppRoles" class="my-4">this application does not contain any roles.</InfoText>
    <InfoBlock v-if="Boolean(selectedBundle) && bundleMetaroles.length === 0" class="text-center">
      This bundle does not contain any metaroles.
    </InfoBlock>
    <div v-if="roleMappingAvailable" class="mt-6">
      <label class="form-label">map {{selectedBundle.name}} bundle metaroles for this application</label>
      <div class="flex text-xs py-3 border-b border-black">
        <div class="col-roles flex items-center">
          <div class="col-metarole">metarole</div>
          <div class="col-approle">app role</div>
        </div>
      </div>
      <div class="pt-2 pb-6 flex">
        <div v-if="!allRolesMapped" class="text-blue-800 p-4 text-sm">
          Please match all meta roles with application roles. You may map one application role to several meta roles.
        </div>
        <div class="col-roles">
          <div v-for="(metarole) in bundleMetaroles" :key="`${selectedBundle.name}-${metarole.name}`" class="py-2 flex items-center">
            <div class="col-metarole">{{metarole.name}}</div>
            <div class="col-approle">
              <SelectInput :name="`metaroles[${metarole.id}].appRoleId`" :options="rolesOptions" :validate="required" :editMode="!readonly">
                <template v-slot:option="{ option, selected }">
                  <div :title="option.description" class="flex w-full items-center">
                    <div class="flex flex-shrink-0 justify-center items-center w-3 h-3 mr-2">
                      <Icon v-if="selected" name="checkmark" class="w-3 h-3"/>
                    </div>
                    <span >{{option.value}}</span>
                  </div>
                </template>
              </SelectInput>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import NotifyMixin from "@/mixins/NotifyMixin";
  import ValidatorMixin from '@/components/form/ValidatorMixin';
  import SelectInput from "@/components/form/SelectInput";
  import Icon from "@/components/ui/Icon";
  import InfoBlock from "@/components/auth/InfoBlock";
  import InfoText from '@/components/ui/InfoText';
  import { ROLES, BUNDLE_TYPES } from '@/components/auth/constants';

export default {
  name: 'ApplicationBundle',
  components: {
    Icon,
    InfoBlock,
    InfoText,
    SelectInput,
  },
  mixins: [
    NotifyMixin,
    ValidatorMixin,
  ],
  props: {
    initialValues: {
      type: Object,
      default: () => ({})
    },
    formValues: {
      type: Object,
      default: () => ({})
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update-roles'],
  computed: {
    appId() {
      return this.$route.params.appId;
    },
    bundleOptions() {
      const suits = this.bundles.map((b) => ({ key: b.id, value: b.name.toLocaleLowerCase() }));

      const { bundleId, bundleType } = this.initialValues;
      const isStandalone = bundleType === BUNDLE_TYPES.STAND_ALONE;

      const standAloneOption = isStandalone
          ? { key: bundleId, value: 'stand alone app' }
          : { key: BUNDLE_TYPES.STAND_ALONE, value: 'stand alone app' };

      return [
        standAloneOption,
        ...suits,
      ];
    },
    selectedBundle() {
      const { bundleId } = this.formValues;
      return !bundleId ? null : this.bundles.find(item => item.id === bundleId);
    },
    bundleMetaroles() {
      return this.selectedBundle?.metaroles || [];
    },
    roleMappingAvailable() {
      return this.rolesOptions.length > 0 && this.bundleMetaroles.length > 0;
    },
    allRolesMapped() {
      const { metaroles } = this.formValues;
      return this.bundleMetaroles.map(({ id }) => metaroles[id]?.appRoleId).every(Boolean);
    },
    noAppRoles() {
      return this.rolesOptions.length === 0;
    },
  },
  data() {
    return {
      bundles: [],
      rolesOptions: [],
    }
  },
  watch: {
    'formValues.metaroles': {
      handler: function(metaroles) {
        const outputRoles = this.bundleMetaroles.map(({ id }) => ({
          id,
          roles: [{ appId: this.appId, id: metaroles[id]?.appRoleId === ROLES.NO_ROLE ? null : metaroles[id]?.appRoleId}],
        }));

        this.$emit('update-roles', outputRoles);
      },
      deep: true,
    },
  },
  methods: {
    fetchBundles() {
      this.$authDataProvider.getList('bundles', { size: 999, type: BUNDLE_TYPES.SUITE, nonDeleted: true })
        .then(({content}) => {
          this.bundles = content;
        }).catch((err) => this.notifyError(err.message));
    },

    fetchAppRoles() {
      const appRolesInUse = Object.values(this.initialValues.metaroles || {}).map(({appRoleId}) => appRoleId);
      this.$authDataProvider.getList('appRoles', { size: 999, appId: this.appId })
          .then(({ content }) => {
            this.rolesOptions = [
                { key: ROLES.NO_ROLE, value: 'No role' },
                ...content
                    .filter(({ appRoleId, deletedAt }) => !deletedAt || appRolesInUse.includes(appRoleId))
                    .map(({ appRoleId, alias, deletedAt, description }) => ({
                      key: appRoleId,
                      value: `${deletedAt ? 'DELETED:' : ''}${alias}`,
                      $isDisabled: Boolean(deletedAt),
                      description,
                    }))
                ];
          })
          .catch((err) => this.notifyError(err.message));
    },
  },
  mounted() {
    Promise.all([
        this.fetchBundles(),
        this.fetchAppRoles(),
    ])
  }
};
</script>

<style scoped>
.col-roles {
  @apply w-2/3 flex-shrink-0;
  margin-left: auto;
}
.col-metarole {
  @apply px-2 w-1/2;
}
.col-approle {
  @apply pl-2 pr-4 w-1/2;
}
  .bundle-name {
    @apply text-base;
  }

</style>

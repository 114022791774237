<template>
  <div>
    <div class="flex justify-between mb-4">
      <div class="form-label">default options</div>
      <ResetAllButton v-if="!noAttributes && !readonly" @click="resetAttributes" />
    </div>
    <InfoText v-if="noAttributes" class="my-4">this application does not contain any options.</InfoText>
    <div v-else class="grid grid-flow-row grid-cols-3 gap-x-8">
      <div v-for="(attribute, index) in data" :key="`${attribute.id}`" class="pb-2">
        <div class="text-sm mb-2">{{attribute.key}}</div>
        <div class="mb-2 attribute-input">
          <ToggleField v-if="attribute.isBoolean" :name="`attributes[${index}].currentValue`" :disabled="readonly" size="small"></ToggleField>
          <TextField v-else :name="`attributes[${index}].currentValue`" :editMode="!readonly" size="xsmall" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TextField from "@/components/form/TextField";
  import ToggleField from "@/components/form/ToggleField";
  import ResetAllButton from "@/components/auth/ResetAllButton";
  import InfoText from "@/components/ui/InfoText";

export default {
  name: 'ApplicationAttributes',
  components: {
    InfoText,
    ResetAllButton,
    ToggleField,
    TextField,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    formProps: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    noAttributes() {
      return this.data.length === 0;
    },
  },
  methods: {
    resetAttributes() {
      this.formProps.batch(() => {
        this.data.forEach((item, index) => {
          this.formProps.change(`attributes[${index}].currentValue`, item.defaultValue);
        });
      });
    }
  }
};
</script>

<style scoped>
  .attribute-input {
    max-width: 6.25rem;
  }
</style>

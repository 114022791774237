<template>
  <div class="mt-6 flex items-center">
    <button
      type="button"
      class="btn-primary"
      @click="testConnection()"
    >
      Test connection
    </button>
    <BeatLoader class="flex-1 ml-4" :loading="loading"/>
    <span class="px-4" v-if="!loading && status && timestamp">
      <div class="text-sm text-red-600" :class="{'text-green-600': status === 'Success'}">{{status}}</div>
      <div class="text-xs">{{timestamp}}</div>
    </span>
  </div>
</template>

<script>
  import moment from 'moment';
  import NotifyMixin from "@/mixins/NotifyMixin";
  import BeatLoader from "@/components/ui/BeatLoader";

  export default {
    name: 'ApplicationCheckConnectivityButton',
    components: {BeatLoader},
    data: function () {
      return {
        loading: false,
        status: undefined,
        timestamp: undefined,
      };
    },
    mixins: [NotifyMixin],
    props: {
      url: {
        type: String
      }
    },
    emits: ['connectionStatus'],
    methods: {
      testConnection() {
        if (!this.loading) {
          if (!this.url) {
            this.notifyError('Enter API endpoint first');
          } else {
            this.loading = true;
            this.$authDataProvider.get('appEndpointStatus', { baseUrl: this.url })
              .then(({isActive}) => {
                this.status = isActive ? 'Success' : 'Error';
                this.timestamp = moment().format('LLL');
                this.$emit('connectionStatus', isActive);
              })
              .catch(error =>  this.notifyError(error.message))
              .finally(() => this.loading = false);
          }
        }
      }
    }
  };
</script>

<style scoped>

</style>

<template>
  <Form
    :submit="handleSubmit"
    :initialValues="initialValues"
    ref="form"
    @change="handleFormChange"
  >
    <template v-slot="props">
      <div>
        <div class="form-row w-1/2">
          <TextField name="name" label="application name*" :validate="required" :editMode="!readonly" />
        </div>
        <div class="form-row items-end">
          <TextField name="apiEndpoint" label="API endpoint" :validate="required" :editMode="!readonly" />
          <div v-if="!readonly" class="">
            <ApplicationCheckConnectivityButton
              @connectionStatus="isActive => props.change('isActive', isActive)"
              :url="formValues.apiEndpoint"
            />
          </div>
        </div>
        <div class="my-6">
          <div class="form-col mb-4">
              <label>settings</label>
          </div>
          <CheckboxInput name="isCommunityAgnostic" label="community agnostic app" :disabled="disableCommunityAgnosticToggle" class="mb-4" />
          <div class="flex items-center mb-4">
            <CheckboxInput
              name="isCustomerUserAgnostic"
              label="user agnostic app"
              :disabled="disableUserAgnosticToggle"
              @change="handleUserAgnosticChange"
            />
            <tooltip
              v-if="!isStandalone && appId"
              icon="exclamationCircle"
              text="User agnostic application can be a standalone bundle only"
              :arrow="false"
              class="ml-1"
            />
          </div>
        </div>
        <InfoText v-if="!appId" class="my-6">please save your application and then synchronize its data</InfoText>
        <div v-if="!readonly" class="form-row">
          <div class="flex items-center">
            <Tooltip :text="syncDisabledTooltip">
              <template v-slot:label>
                <button @click="handleSync" :disabled="syncDisabled" type="button" class="btn-primary mr-4">
                  synchronize
                </button>
              </template>
            </Tooltip>
            <div v-if="isSyncing">sync in progress...</div>
            <div v-if="!isSyncing && syncData">
              <drop-menu min-width="400px" direction="top">
                <template v-slot:header>
                  <a class="cursor-pointer text-sm text-blue-500 font-500 lowercase">
                    last {{ syncData.success ? '' : ' un'}}successfully synchronized {{ formatDate(syncData.updatedAt) }}
                  </a>
                </template>
                <template v-slot:default>
                  <div class="w-full flex flex-wrap">
                  <div class="sync-section">
                    <div class="label">communities</div>
                    <div class="sync-subsection" v-for="(value, label) in syncData.communitySyncResult"
                         :key="`com_${label}`">
                      {{ label }}: {{ value }}
                    </div>
                  </div>
                  <div class="sync-section">
                    <div class="label">customers</div>
                    <div class="sync-subsection" v-for="(value, label) in syncData.customerSyncResult"
                         :key="`cus_${label}`">
                      {{ label }}: {{ value }}
                    </div>
                  </div>
                  <div class="sync-section">
                    <div class="label">profiles</div>
                    <div class="sync-subsection" v-for="(value, label) in syncData.profileSyncResult"
                         :key="`pro_${label}`">
                      {{ label }}: {{ value }}
                    </div>
                  </div>
                  <div class="sync-section">
                    <div class="label">admins</div>
                    <div class="sync-subsection" v-for="(value, label) in syncData.superAdminSyncResult"
                         :key="`su_${label}`">
                      {{ label }}: {{ value }}
                    </div>
                  </div>
                </div>
              </template>
            </drop-menu>
          </div>
          </div>
        </div>
        <InfoBlock v-if="alarmsSlackChannel">
          <div>For troubleshooting and debugging purposes upon the first error the following channel will become available in Slack:</div>
          <div class="font-600 mt-4">{{alarmsSlackChannel}}</div>
        </InfoBlock>
        <application-attributes
          v-if="appId"
          :data="initialValues.attributes"
          :form-props="props"
          :readonly="readonly"
          class="mt-10"
        ></application-attributes>
        <application-bundle
          v-if="appId"
          :initial-values="initialValues"
          :form-values="formValues"
          :readonly="readonly || props.values.isCustomerUserAgnostic"
          class="mt-8"
          @update-roles="updateRolesMapping"
        ></application-bundle>
        <ModalFooter/>
      </div>
    </template>
  </Form>
</template>

<script>
  import moment from "moment";
  import { createNamespacedHelpers } from 'vuex';
  import ConfirmationMixin from "@/mixins/ConfirmationMixin";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import ValidatorMixin from '@/components/form/ValidatorMixin';
  import Form from "@/components/form/Form";
  import Tooltip from "@/components/ui/Tooltip";
  import TextField from '@/components/form/TextField';
  import ModalFooter from "@/components/ui/modals/ModalFooter";
  import CheckboxInput from "@/components/auth/form/CheckboxInput";
  import DropMenu from "@/components/ui/DropMenu";
  import ApplicationCheckConnectivityButton from '@/views/auth/applications/ApplicationCheckConnectivityButton';
  import ApplicationBundle from "@/views/auth/applications/ApplicationBundle";
  import ApplicationAttributes from "@/views/auth/applications/ApplicationAttributes";
  import { BUNDLE_TYPES } from "@/components/auth/constants";
  import InfoText from '@/components/auth/InfoText';
  import InfoBlock from '@/components/auth/InfoBlock';
const { mapActions, mapGetters } = createNamespacedHelpers('appSync');

export default {
  name: 'ApplicationForm',
  components: {
    InfoBlock,
    InfoText,
    ApplicationAttributes,
    ApplicationBundle,
    DropMenu,
    Form,
    CheckboxInput,
    ApplicationCheckConnectivityButton,
    Tooltip,
    TextField,
    ModalFooter
  },
  mixins: [
    ConfirmationMixin,
    ValidatorMixin,
    NotifyMixin
  ],
  props: {
    onSubmit: {
      type: Function,
      required: true,
    },
    initialValues: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['appIsSyncing']),
    appId() {
      return this.$route.params.appId;
    },
    alarmsSlackChannel() {
      return this.formValues.alarmsSlackChannel;
    },
    disableCommunityAgnosticToggle() {
      return (this.initialValues.appInfo && this.initialValues.appInfo.isCommunityAgnostic !== null) || this.readonly;
    },
    disableUserAgnosticToggle() {
      return (this.initialValues.appInfo && this.initialValues.appInfo.isCustomerUserAgnostic !== null)
          || this.readonly
          || (!this.isStandalone && this.appId);
    },
    readonly() {
      return Boolean(this.initialValues.deletedAt);
    },
    syncDisabled() {
      return this.isSyncing || !this.appId || (this.formValues.status !== 'ACTIVE' && !this.formValues.isActive);
    },
    syncDisabledTooltip() {
      if (this.isSyncing) {
        return 'sync in progress';
      }

      if (!this.appId) {
        return 'save an application before synchronizing its data';
      }

      if (this.formValues.status !== 'ACTIVE' && !this.formValues.isActive) {
        return 'test connection before synchronizing data';
      }

      return null;
    },
  },
  data() {
    return {
      isSyncing: false,
      timeoutId: undefined,
      syncData: undefined,
      isStandalone: false,
      metaroles: [],
      formValues: {},
    }
  },
  watch: {
    'initialValues.lastAppSyncId': async function (val) {
      if (val) {
        const {status} = await this.getSyncStatus(val);
        if (status === 'IN_PROGRESS') {
          this.startSyncStatusPolling(val);
        } else {
          this.isSyncing = false;
        }
      }
    }
  },
  methods: {
    ...mapActions(['appSyncStarted', 'appSyncStopped']),
    handleFormChange(state) {
      this.formValues = state.values;
    },
    async handleSync() {
      this.isSyncing = true;
      const syncRequest = await this.startSync();
      this.startSyncStatusPolling(syncRequest.id);
    },
    startSync() {
      return this.$authDataProvider.create('appSync', {appId: this.appId})
        .catch(error => this.notifyError(error.message))
    },
    startSyncStatusPolling(syncId) {
      const oldTimeoutId = this.appIsSyncing(this.appId);

      if (oldTimeoutId && oldTimeoutId !== this.timeoutId) {
        clearTimeout(oldTimeoutId);
      }

      this.timeoutId = setTimeout(async () => {
        const {status} = await this.getSyncStatus(syncId);

        if (status === 'IN_PROGRESS') {
          this.startSyncStatusPolling(syncId);
        } else {
          this.isSyncing = false;
          this.appSyncStopped(this.appId);
          this.notifySuccess(`${this.initialValues.name} app is synchronized`);
        }
      }, 9000);

      this.appSyncStarted({appId: this.appId, timeoutId: this.timeoutId})
    },
    async getSyncStatus(syncId) {
      this.isSyncing = true;
      const {status, ...data} = await this.$authDataProvider.get('appSyncResult', {syncId});
      this.syncData = data;

      return {status, ...data};
    },
    formatDate(value) {
      return moment(value).format('lll');
    },
    updateRolesMapping(payload) {
      this.metaroles = payload;
    },
    handleSubmit({ attributes = [], bundleId, ...values }) {
      const attributesPayload = attributes.map((attr) => ({
        attributeId: attr.id,
        value: attr.currentValue,
      }));

      this.onSubmit({
        ...values,
        bundleId: this.isStandalone ? null : bundleId,
        metaroles: this.metaroles,
        attributes: attributesPayload,
      });
    },
    handleUserAgnosticChange(value) {
      if (this.loading) {
        return;
      }

      if (value) {
        this.requestConfirmation({
          confirmationMessage: 'User agnostic applications can not be included into suite bundles and are available for all customers by default. Are you sure you want to make this application user agnostic?',
          confirmBtnText: 'yes',
          cancelBtnText: 'no',
          confirmationType: 'warning'
        })
          .then(confirmed => {
            if (!confirmed) {
              this.$refs.form.formApi.change('isCustomerUserAgnostic', false);
            }
          });
      }
    },
  },
  mounted() {
    this.$watch(
        () => this.$refs.form.formState.values.bundleId,
        val => {
          this.isStandalone =
              (val === this.initialValues.bundleId && this.initialValues.bundleType === BUNDLE_TYPES.STAND_ALONE)
              || val === BUNDLE_TYPES.STAND_ALONE;
        }
    );
  },
  beforeUnmount() {
    if (this.isSyncing) {
      clearTimeout(this.timeoutId);
      this.appSyncStopped(this.appId);
    }
  }
};
</script>

<style scoped>
.sync-section {
  @apply w-1/2 text-xs;
}

.sync-section .label {
  @apply text-base font-bold my-2;
}

.sync-subsection {
  @apply inline-block w-1/2;
}
</style>
